// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/background-1.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/background-2.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".sliced-clip[data-v-fae5bece] {\n  -webkit-clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);\n          clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);\n}\n.clip-1[data-v-fae5bece] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center / cover\n    no-repeat;\n}\n.clip-2[data-v-fae5bece] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") center center / cover\n    no-repeat;\n  background-color: red;\n}\n.sliced-clip-3[data-v-fae5bece] {\n  margin-bottom: -1px;\n  width: 100%;\n  height: 100px;\n  position: relative;\n}\n.sliced-clip-3[data-v-fae5bece]::after {\n  background-color: red;\n  position: absolute;\n  content: \"\";\n  -webkit-clip-path: polygon(100% 0, 0 100%, 100% 100%);\n          clip-path: polygon(100% 0, 0 100%, 100% 100%);\n  height: 100%;\n  width: 100%;\n}\n.clip-3[data-v-fae5bece] {\n  background: red;\n}\n.outline[data-v-fae5bece] {\n  border: 2px red solid;\n  color: red;\n}\n", ""]);
// Exports
module.exports = exports;

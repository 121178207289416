<template>
  <div class="relative">
    <div class="sliced-clip clip-1 pb-20">
      <div class="py-8 px-4">
        <img src="../assets/images/rwp-logo.png" class="lg:ml-6" />
        <div class="flex items-end mt-4 md:justify-end lg:mr-6">
          <button
            class="red-theme-bg py-2 text-white w-1/2 rounded-full text-center font-bold text-base md:w-auto md:px-14"
            @click="toggleModal()"
          >
            Free Demo
          </button>
          <router-link
            to="/login"
            class="rounded-full py-2 red-theme-border w-1/2 text-center red-theme-text ml-3 text-base md:w-auto md:px-14"
          >
            Login
          </router-link>
        </div>
      </div>
      <div class="py-8 px-4 flex flex-col md:flex-row lg:px-20 xl:px-40">
        <div>
          <div class="text-red-400 text-3xl font-bold lg:text-4xl">
            Grow Your Google Reviews
          </div>
          <div class="text-white mt-3 text-sm lg:text-xl lg:mt-7">
            Here at ReviewWorxPro.com, we provide small business tools to reach
            your customers in real life (IRL). With this easy to use interface,
            you can send branded text messages straight to your ideal customer's
            phone.
          </div>
          <div class=" font-bold text-xl text-white mt-5 lg:mt-7 lg:text-2xl">
            Reach Paying Customers,
          </div>
          <div class="text-red-500 font-bold text-xl">
            Grow Your Business!
          </div>
          <button
            class="red-theme-bg py-2 mt-5 text-white w-1/2 rounded-full font-bold text-base lg:w-auto lg:px-14 lg:mt-7"
            @click="toggleModal()"
          >
            Free Demo
          </button>
        </div>
        <img
          class="mt-4 md:home-banner-md md:mt-0 "
          src="../assets/images/home_banner.png"
        />
      </div>
    </div>

    <div class="clip-2 pb-10 lg:pb-24 lg:pt-24">
      <div class="flex flex-col md:flex-row">
        <div class="md:mt-5">
          <img
            src="../assets/images/slide-1.png"
            class="block mx-auto md:h-56 lg:h-auto"
          />
          <div class="text-center font-semibold text-xl lg:mt-5 lg:text-3xl">
            Google Review
          </div>
          <div
            class="text-center text-sm font-semibold mt-2 px-2 lg:mt-6 lg:text-xl"
          >
            Get more Google reviews with ease using this simplistic feature.
          </div>
        </div>

        <div class="mt-5">
          <img
            src="../assets/images/slide-2.png"
            class="block mx-auto md:h-56 lg:h-auto"
          />
          <div class="text-center font-semibold text-xl lg:mt-5 lg:text-3xl">
            Group Texting
          </div>
          <div
            class="text-center text-sm font-semibold mt-2 px-2 lg:mt-6 lg:text-xl"
          >
            Customers only see a business while any and all staff members can
            respond and provide the best possible customer service.
          </div>
        </div>

        <div class="mt-5">
          <img
            src="../assets/images/slide-3.png"
            class="block mx-auto md:h-56 lg:h-auto"
          />
          <div class="text-center font-semibold text-xl lg:mt-5 lg:text-3xl">
            Website Chat
          </div>
          <div
            class="text-center text-sm font-semibold mt-2 px-2 lg:mt-6 lg:text-xl"
          >
            Allow your customers to text your business directly from your
            website.
          </div>
        </div>
      </div>
    </div>
    <div class="sliced-clip-3"></div>
    <div class="clip-3 py-12 sliced-clip lg:pb-20 ">
      <div class="flex flex-col md:flex-row ">
        <div class="md:w-1/2 lg:mx-12">
          <div
            class="text-white text-2xl font-bold text-center xl:text-5xl lg:text-left lg:ml-4 lg:mb-2"
          >
            Did You Know?
          </div>
          <div class="text-sm text-white p-4 text-justify xl:text-xl xl:py-7">
            The average person checks their phone every 12 minutes and 90% of
            text messages are read within 3 minutes.
          </div>
          <div class="text-sm text-white p-4 text-justify xl:text-xl xl:py-7">
            As a small business, you NEED to be using text marketing to reach
            your optimal customers. ReviewWorxPro can help you design successful
            text messaging campaigns that bring paying customers.
          </div>
          <div class="text-sm text-white p-4 text-justify xl:text-xl xl:py-7">
            Reach Out Today And Click The "Chat Now" On The Bottom Right Of The
            Page.
          </div>
          <div
            class="text-white font-bold text-base p-4 text-justify xl:text-2xl"
          >
            Reach Out Today And Click The "Chat Now" On The Bottom Right Of The
            Page.
          </div>
        </div>
        <div class="md:w-1/2 lg:w-1/2 relative md:bottom-0 overflow-visible">
          <img
            src="../assets/images/girl_image.png"
            class="md:absolute md:bottom-0 md:right-0 lg:h-auto"
          />
        </div>
      </div>
    </div>
    <div class="clip-4 py-16 lg:py-36">
      <div class="text-center font-bold text-2xl lg:text-5xl">
        Over 100,000 Messages Sent So Far!
      </div>
      <div class="px-8 flex flex-col md:flex-row md:justify-center lg:mt-10">
        <div class="mt-5">
          <img src="../assets/images/company-1.png" class="block mx-auto" />
        </div>
        <div class="mt-5 md:ml-6">
          <img src="../assets/images/company-2.png" class="block mx-auto" />
        </div>
      </div>
    </div>

    <div
      class="bg-gray-900 text-sm p-4 text-center font-bold text-white lg:p-10 lg:text-xl"
    >
      <span> Copyright © {{ currentYear }} </span> •
      <router-link class="text-red-500" to="/"> ReviewWorxPro </router-link> •
      <span> All Rights Reserved </span>
    </div>

    <div
      class="h-screen w-full fixed top-0 right-0 bg-gray-700 opacity-25"
      v-if="showPopup"
    ></div>

    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0"
      v-if="showPopup"
    >
      <div
        class="absolute h-screen md:h-auto w-full md:max-w-2xl md:flex md:items-center md:justify-center bg-modal"
      >
        <div
          class="bg-white rounded shadow pb-8 md:m-4 md:max-h-full h-screen md:h-full overflow-y-hidden text-center"
        >
          <div class="mb-4 clip-3 py-4 text-white text-base">
            Free Demo
          </div>
          <form @submit.prevent="requestDemo" class="w-full inline-block">
            <div
              class="w-full text-xs form-wrapper text-gray-600 text-justify px-6"
            >
              <p class="w-full">
                Take a moment to discover how Review Worx Pro can help you to
                drive sales, increase marketshare and turn every day customers
                into super fans! Review Worx Pro is easy to install, simple to
                use and available to download on both Apple and Android mobile
                devices.
              </p>
              <div class="items-center flex flex-col mt-7">
                <div class="flex flex-col md:flex-row justify-evenly w-full">
                  <div class="flex mb-3 flex-col md:w-1/2">
                    <label
                      class="uppercase font-bold text-sm text-gray-500"
                      for="business-code"
                    >
                      Name
                    </label>
                    <input
                      class="border py-2 px-3 text-grey-darkest"
                      type="text"
                      name="name"
                      v-model="request.name"
                      autocomplete="off"
                      required
                      id="name"
                    />
                  </div>
                  <div class="flex flex-col mb-3 md:w-1/2 md:ml-3">
                    <label
                      class="uppercase font-bold text-sm text-gray-500"
                      for="business-code"
                    >
                      Phone Number
                    </label>
                    <input
                      class="border py-2 px-3 text-grey-darkest"
                      type="tel"
                      ref="phone"
                      name="phone"
                      autocomplete="off"
                      @change="validatePhone"
                      v-model="request.phone"
                      required
                      id="phone"
                    />
                  </div>
                </div>

                <div class="flex flex-col mb-6 w-full">
                  <label
                    class="uppercase font-bold text-sm text-gray-500"
                    for="business-code"
                  >
                    Email
                  </label>
                  <input
                    class="border py-2 px-3 text-grey-darkest"
                    type="email"
                    name="email"
                    autocomplete="off"
                    required
                    v-model="request.email"
                    id="email"
                  />
                </div>
              </div>
              <div role="alert" class="my-3" v-if="successMsg != ''">
                <div
                  class="border border-green-500 rounded bg-green-100 text-sm px-4 py-3 text-green-700"
                >
                  <p>{{ successMsg }}.</p>
                </div>
              </div>

              <div role="alert" class="my-3" v-if="errorMsg != ''">
                <div
                  class="border border-red-400 rounded bg-red-100 text-sm px-4 py-3 text-red-700 text-center"
                >
                  <p>{{ errorMsg }}.</p>
                </div>
              </div>
            </div>

            <div class="flex-row px-3 md:flex-row md:px-0 justify-center">
              <button
                class="w-full md:w-1/3 md:mr-3 flex-no-shrink text-white py-2 px-4 rounded-full clip-3"
                type="submit"
                :disabled="errorMsg != ''"
                :class="errorMsg != '' ? 'opacity-50 cursor-not-allowed' : ''"
              >
                Request Demo
              </button>
              <button
                type="button"
                class="w-full mt-3 md:mt-0 md:w-1/3 md:ml-3 flex-no-shrink outline border-2 py-2 px-4 rounded-full"
                @click="toggleModal()"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const firebase = require("../firebaseConfig");

export default {
  name: "Home",
  components: {},
  data: () => ({
    currentYear: new Date().getUTCFullYear(),
    showPopup: false,
    request: {
      name: "",
      phone: "",
      email: "",
    },
    successMsg: "",
    errorMsg: "",
  }),
  methods: {
    toggleModal() {
      this.showPopup = !this.showPopup;
      this.request = {
        name: "",
        phone: "",
        email: "",
      };
      document.querySelector("body").style.overflowY = this.showPopup
        ? "hidden"
        : "auto";
    },
    async requestDemo() {
      const phone = this.request.phone
        .replace(/[^0-9]/g, "")
        .replace(/^1/g, "");
      this.errorMsg = "";

      if (phone.length != 10) {
        this.errorMsg = "Phone number must be exactly 10 digit";
        return false;
      }

      this.request.phone = phone;
      const sendingEmail = firebase.functions.httpsCallable("sendEmail");

      const data = await sendingEmail(this.request);

      if (data.data.result !== "request succesfully sent") {
        this.showPopup = !this.showPopup;
      } else {
        this.errorMsg = "There is an error while processing the request";
      }
    },
    validatePhone() {
      this.errorMsg = "";
      this.request.phone = this.request.phone
        .replace(/[^0-9]/g, "")
        .replace(/^1+/g, "");
      if (this.request.phone.length != 10) {
        this.errorMsg = `Phone number has to be exactly 10 digit`;
        this.$refs.phone.focus();
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.sliced-clip {
  clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
}
.clip-1 {
  background: url("../assets/images/background-1.jpg") center center / cover
    no-repeat;
}
.clip-2 {
  background: url("../assets/images/background-2.jpg") center center / cover
    no-repeat;
  background-color: red;
}
.sliced-clip-3 {
  margin-bottom: -1px;
  width: 100%;
  height: 100px;
  position: relative;
}
.sliced-clip-3::after {
  background-color: red;
  position: absolute;
  content: "";
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
  height: 100%;
  width: 100%;
}
.clip-3 {
  background: red;
}
.outline {
  border: 2px red solid;
  color: red;
}
</style>
